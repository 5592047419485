import * as Schema from 'generated/graphql/schema';
import { useCallback, useContext } from 'react';

import { UserContext } from '@/contexts/user';
import * as Feature from '@/lib/features';
import * as Types from '@/types';
import { Claim, Module } from '@/types/auth-types';

export const makeModuleAccessChecker =
  (claims: Schema.MeQuery['claims'] | undefined) =>
  (module: Module): boolean => {
    let claimIndex = claims?.index.find((x) => x.name === Claim.ModuleAccess.toString())?.id;
    if (claimIndex === undefined) {
      return false;
    }
    const moduleStr = module.toString();
    const claimsForResource = claims?.resources.find((x) => x.key === `module|${moduleStr}`)?.claims ?? [];
    let res = claimsForResource.find((x) => x === claimIndex);
    return res !== undefined;
  };

export interface IAM {
  user: Partial<Types.ExtendedUser>;
  features: Feature.FeatureMap;
  hasPeripheralFeatures: Feature.HasItemFeatures;
  hasGroupFeatures: Feature.HasItemFeatures;
  hasLineFeatures: Feature.HasItemFeatures;
  hasFeatureInAnyGroup: (lookupFeatures: string[], featureType: 'M' | 'Q') => boolean;
  hasFeatureInAnyUserPool: (lookupFeatures: string[], featureType: 'M' | 'Q') => boolean;
  hasModuleAccess: (module: Module) => boolean;
}

export const useIAM = (): IAM => {
  const user = useContext(UserContext);

  const hasModuleAccess = useCallback(makeModuleAccessChecker(user.claims), [user.claims]);

  return {
    user,
    features: (user as any).features as Feature.FeatureMap,
    hasPeripheralFeatures: Feature.generateForItem((user as any).features, 'peripheral'),
    hasGroupFeatures: Feature.generateForItem((user as any).features, 'group'),
    hasLineFeatures: Feature.generateForItem((user as any).features, 'line'),
    hasFeatureInAnyGroup: Feature.inAnyGroup((user as any).features),
    hasFeatureInAnyUserPool: Feature.inAnyUserPool((user as any).features),
    hasModuleAccess,
  };
};
